import { TicketshopPage } from "components/TicketshopPage";
import React from "react";

export default function BetterNightsPage() {
  return (
    <TicketshopPage
      scriptString={`<script src="https://web-cdn.fixr.co/scripts/fixr-shop-widget.v1.min.js?salesChannel=whitelabel-website" data-name-filter="better" data-fixr-shop-id="be31447a-9d7b-4108-bde6-c9cb9a5287e1" data-fixr-theme="light"></script>`}
      imageSrc="/logo/better-nights-logo.png"
      backgroundSrc="/hero/better-nights-hero.jpg"
      imageMaxWidth="322px"
      title="BETTER NIGHTS"
      description={
        <>
          <p>
            The neon soaked sibling of Better Days Festival lights up the
            darkness of night, with an electrifying rave stacked full of bright
            lights & big sounds at Beaver Works. An all night indoor festival
            full of music, dancing, interactive installations, entertainment and
            unmatched vibes. Explore 7 areas of enchantment, guest brand
            takeovers, big B2B DJ sets, silent discos and insane funfair rides
            at our nighttime utopia.
          </p>
          <p> Better Nights are emerging!</p>
        </>
      }
    />
  );
}
